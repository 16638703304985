import { Badge, Table } from "@radix-ui/themes";
import { EyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { TClientsSearchResponse } from "src/@types/clients";
import ClientStatus from "src/components/ClientStatus";
import Filter, { FTypes } from "src/components/Filter";
import MainLayout from "src/components/MainLayout";
import Clients from "src/infraestructure/services/api/Clients";

const SearchClients = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [client, setClient] = useState<TClientsSearchResponse>();
    const [status, setStatus] = useState<string>("");

    const handleFilter = async (e: any) => {
        setLoading(true);
        try {
            const request = await Clients.get(e);
            if (request && request.data) {
                setClient(request.data as TClientsSearchResponse);
            }
            setLoading(false);
        } catch(err){
            setLoading(false);
        }
    }

    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "PersonalId",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "personalId",
                        options: [
                            {
                                label: "CPF",
                                value: "personalId",
                            },
                            {
                                label: "Email",
                                value: "email",
                            },
                            {
                                label: "ID",
                                value: "clientId",
                            },
                            {
                                label: "Telefone",
                                value: "phone",
                            },
                        ],
                    }
                ]}
                onPress={handleFilter}
                loading={loading}
            />
            <Table.Root variant="surface" mt={'20px'}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Saldo</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>CPF</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Telefone</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>BTag</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Endereço</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Verificado</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Ações</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>

                {client &&
                    <Table.Body>
                        <Table.Row>
                            <Table.RowHeaderCell>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10, cursor: 'pointer' }}>
                                <EyeIcon style={{ cursor: 'pointer' }} onClick={() => {
                                    navigate(`/clients/${client.id}`);
                                }} />{client.id}</div></Table.RowHeaderCell>
                            <Table.Cell>{client.name}</Table.Cell>
                            <Table.Cell>R${client.balance}</Table.Cell>
                            <Table.Cell>{client.personal_id}</Table.Cell>
                            <Table.Cell>{client.email}</Table.Cell>
                            <Table.Cell>{client.phone}</Table.Cell>
                            <Table.Cell>{!client.btag ? "-" : client.btag}</Table.Cell>
                            <Table.Cell>{client.address}</Table.Cell>
                            <Table.Cell>
                                <Badge color={client.is_verified ? "green" : "red"}>{client.is_verified ? "Sim" : "Não"}</Badge>
                            </Table.Cell>
                            <Table.Cell style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <ClientStatus userId={String(client.id)} onFinish={(e: string) => {
                                    setStatus(e);
                                }} />
                                {status ? <Badge color={status === "online" ? "green" : "red"}>{status}</Badge> : <div>-</div>}
                            </Table.Cell>                            
                            <Table.Cell>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                }
            </Table.Root>
        </MainLayout>
    );
}

export default SearchClients;