import { extractTimeFromMessage, handleFilterGateway } from 'src/utils';
import axiosInstance from './AxiosInstance';
import axios, { AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import i18n from 'i18next';

interface GatewayConfig {
  url: string;
  method?: string;
  data?: Partial<any>;
  filters?: { name: string; value: string }[];
  id?: string | number;
  type?: string
  timeout?: number;
  lastArg?: string,
}

const Gateway = async ({
  url,
  method = 'GET',
  data,
  filters,
  id,
  lastArg,
  type,
}: GatewayConfig) => {
  if (!url) {
    throw new Error('A rota precisa ser especificada');
  }

  if (id && !url.includes(':id')) {
    url = `${url}/${id}`;
  }

  if (id && url.includes(':id')) {
    url = url.replace(':id', id.toString());
  }

  if (type && url.includes(':type')) {
    url = url.replace(':type', type);
  }

  if (lastArg && url.includes(`:${lastArg}`) && data && data[lastArg]) {
    url = url.replace(`:${lastArg}`, data[lastArg]);
    const { [lastArg]: _, ...restData } = data;
    data = restData;
  }

  const config: AxiosRequestConfig = {
    url: `${url}${filters ? handleFilterGateway(filters) : ''}`,
    method,
    data,
  };

  try {
    const response = await axiosInstance(config);
    return {
      ...response.data,
      data: response.data.data,
      status: response.status,
      message: response.data?.message || '',
    };
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (err.request.responseURL === "https://backoffice.api.oig.dev/v1/admin/config/casino/sync") {
        const message = err.response?.data?.message || '';
        const { hours, minutes, seconds } = extractTimeFromMessage(message);
        toast.error(i18n.t('synchronizationMessage', { hours, minutes, seconds }));
      } else {
        const errorMessage = err.response?.data?.error?.message || i18n.t('Unknown_error');
        toast.error(i18n.t(errorMessage));
      }
    } else {
      toast.error(i18n.t('Unknown_error'));
    }
    throw err;
  }
};

export default Gateway;

