import { TDataPut } from "src/@types/admin";
import Gateway from "../../Gateway";

const route = "/v1/admin/config/payments";
const routePut = "/v1/admin/config/payments/edit";
const routeSynchronize = "/v1/admin/config/casino/sync";
const routeGetSynchronize = "/v1/admin/config/casino/sync/status";

const get = async () =>
  Gateway({
    url: route,
  });

const put = async (data: TDataPut) =>
  Gateway({ url: routePut, method: "PUT", data, timeout: 60000 });

const sync = async () =>
  Gateway({ url: routeSynchronize, method: "POST", timeout: 60000 });

const syncStatus = async () =>
  Gateway({
    url: routeGetSynchronize,
  });

export default {
  get,
  put,
  sync,
  syncStatus,
};
