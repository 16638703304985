import { Badge, Select, Table } from "@radix-ui/themes";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { KYCSearchClientsResponse } from "src/@types/kyc";
import Filter, { FTypes } from "src/components/Filter";
import MainLayout from "src/components/MainLayout";
import KYC from "src/infraestructure/services/api/KYC";

const SearchKYC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [client, setClient] = useState<KYCSearchClientsResponse>();
    const [status, setStatus] = useState<string>("");
    const [cpf, setCpf] = useState<string>("");
    const [verified, setVerified] = useState<boolean>();

    const handleFilter = async (e: any) => {
        setLoading(true);
        try {
            const request = await KYC.get(e);
            setCpf(e.personalId);
            if (request && request.data) {
                setClient(request.data as KYCSearchClientsResponse);
                if(request.data) {
                    setVerified((request.data as KYCSearchClientsResponse).verified);
                }
            }
            setLoading(false);
        } catch(err){
            setLoading(false);
        }
    }

    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "PersonalId",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "personalId",
                        options: [
                            {
                                label: "CPF",
                                value: "personalId",
                            },
                            {
                                label: "Email",
                                value: "email",
                            }
                        ],
                    }
                ]}
                onPress={handleFilter}
                loading={loading}
            />
            <Table.Root variant="surface" mt={'20px'}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Data de Nascimento</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Idade</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>PEP</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell>Verificado</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>

                {client &&
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{client.name}</Table.Cell>
                            <Table.Cell>{client.birthday}</Table.Cell>
                            <Table.Cell>{client.age}</Table.Cell>
                            <Table.Cell>
                                {client.is_pep ? <Badge color={client.is_pep ? "green" : "red"}>Sim</Badge> : <Badge color={client.is_pep ? "green" : "red"}>Não</Badge> }
                            </Table.Cell>
                            <Table.Cell>
                            <Select.Root
                                    value={verified ? "yes" : "no"}
                                    onValueChange={async (e) => {
                                        const put = await KYC.edit(cpf);
                                        setVerified(e == "yes" ? true : false);
                                        if(put.status === 200) {
                                            toast.success("Atualizado com sucesso!")
                                        }
                                    }}
                                    size={"1"}
                                >
                                    <Select.Trigger />
                                    <Select.Content style={{ width: 300 }}>
                                    <Select.Group>
                                        <Select.Label>{t("Status")}</Select.Label>
                                        <Select.Item value={"yes"}>Sim</Select.Item>
                                        <Select.Item value={"no"}>Não</Select.Item>
                                    </Select.Group>
                                    </Select.Content>
                                </Select.Root>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                }
            </Table.Root>
        </MainLayout>
    );
}

export default SearchKYC;