import { lazy } from "react";
import { AuthRouteObject } from "react-router-auth-plus";
import { RequireAuth } from "src/App";
import AdminConfig from "src/pages/AdminConfig";
import AdminLogs from "src/pages/AdminLogs";
import AdminUsers from "src/pages/AdminUsers";
import AffiliatesList from "src/pages/AffiliatesList";
import AffiliatesTransactions from "src/pages/AffiliatesTransactions";
import AffiliatesUser from "src/pages/AffiliatesUser";
import ClientProfile from "src/pages/ClientProfile";
import CreateUser from "src/pages/CreateUser";
import Deposits from "src/pages/Deposits";
import SearchKYC from "src/pages/KYCList";
import PaymentMethods from "src/pages/PaymentMethods";
import PendingTransactions from "src/pages/PendingTransactions";
import ReportByBet from "src/pages/ReportByBet";
import ReportByBetSportbook from "src/pages/ReportByBetSportbook";
import ReportByGame from "src/pages/ReportByGame";
import ReportByPlayerSportbook from "src/pages/ReportByPlayerSportbook";
import SearchClients from "src/pages/SearchClients";
import WithdrawalRequests from "src/pages/WithdrawalRequests";

const Home = lazy(() => import("../../pages/Home"));
const Login = lazy(() => import("../../pages/Login"));
const Unauthorized = lazy(() => import("../../pages/Unauthorized"));

type MetaMenu = {
  name?: string;
  icon?: React.ReactNode;
};

export type MetaMenuAuthRouteObject = AuthRouteObject<MetaMenu>;

export const routers: MetaMenuAuthRouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/clients/list",
    element: <RequireAuth path="/clients/list" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><SearchClients /></RequireAuth>,
  },
  {
    path: "/clients/:id",
    element: <RequireAuth path="/clients/:id" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><ClientProfile /></RequireAuth>,
  },
  {
    element: <RequireAuth path="/home" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><Home /></RequireAuth>,
    path: "/home"
  },
  {
    element: <RequireAuth path="/deposits/list" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><Deposits /></RequireAuth>,
    path: "/deposits/list"
  },
  {
    element: <RequireAuth path="/admin/users" roles={['ADMINISTRATOR']}><AdminUsers /></RequireAuth>,
    path: "/admin/users"
  },
  {
    element: <RequireAuth path="/admin/edit/:id" roles={['ADMINISTRATOR']}><CreateUser /></RequireAuth>,
    path: "/admin/edit/:id"
  },
  {
    element: <RequireAuth path="/admin/create" roles={['ADMINISTRATOR']}><CreateUser /></RequireAuth>,
    path: "/admin/create"
  },
  {
    element: <RequireAuth path="/admin/payments" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><PaymentMethods /></RequireAuth>,
    path: "/admin/payments"
  },
  {
    element: <RequireAuth path="/affiliates/list" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><AffiliatesList /></RequireAuth>,
    path: "/affiliates/list"
  },
  {
    element: <RequireAuth path="/affiliates/:id" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><AffiliatesUser /></RequireAuth>,
    path: "/affiliates/:id"
  },
  {
    element: <RequireAuth path="/admin/logs" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_1', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><AdminLogs /></RequireAuth>,
    path: "/admin/logs"
  },
  {
    element: <RequireAuth path="/admin/transactions/pending" roles={['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><PendingTransactions /></RequireAuth>,
    path: "/admin/transactions/pending"
  },
  {
    element: <RequireAuth path="/affiliates/transactions" roles={['ADMINISTRATOR']}><AffiliatesTransactions /></RequireAuth>,
    path: "/affiliates/transactions"
  },
  {
    element: <RequireAuth path="/withdrawal/requests" roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']}><WithdrawalRequests /></RequireAuth>,
    path: "/withdrawal/requests"
  },
  {
    element: <RequireAuth path='/bonus/report' roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']}><ReportByBet /></RequireAuth>,
    path: '/bonus/report'
  },
  {
    element: <RequireAuth path='/live-casino/report' roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']}><ReportByGame /></RequireAuth>,
    path: '/live-casino/report'
  },
  {
    element: <RequireAuth path='/bet/list' roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']}><ReportByBetSportbook /></RequireAuth>,
    path: '/bet/list'
  },
  {
    element: <RequireAuth path='/bet/report' roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']}><ReportByPlayerSportbook /></RequireAuth>,
    path: '/bet/report'
  },
  {
    element: <RequireAuth path='/admin/config' roles={['ADMINISTRATOR']}><AdminConfig /></RequireAuth>,
    path: '/admin/config'
  },
  {
    element: <RequireAuth path='/kyc/list' roles={['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']}><SearchKYC /></RequireAuth>,
    path: '/kyc/list'
  },
  { path: "*", element: <Unauthorized /> },
];