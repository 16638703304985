import { Button, Dialog, Flex, Spinner } from '@radix-ui/themes';
import { BookmarkIcon } from 'lucide-react';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Bonus from 'src/infraestructure/services/api/Bonus';
import { removeCurrencyFormatting } from 'src/utils';
import CurrencyInput from '../CurrencyInput';

type TBonusCancelComp = {
    bonusId: string;
    userId: string;
}

type TBonusActiveForm = {
    value: string;
}

const ActiveBonus = ({ bonusId, userId }: TBonusCancelComp) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<TBonusActiveForm>()
    const onSubmit: SubmitHandler<TBonusActiveForm> = async (data) => {
        setLoading(true);
        await Bonus.active({
            clientId: parseInt(userId),
            bonusId: bonusId.toString(),
            value: removeCurrencyFormatting(data.value),
        });
        setLoading(false);
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <Button color="green" style={{ fontSize: 12 }}>Ativar</Button>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
                <Dialog.Title>Ativar Bônus</Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction="column" gap="3">
                        <label>
                            <Controller
                                name="value"
                                control={control}
                                render={({ field }) => (
                                    <CurrencyInput
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                            {errors.value &&
                                <p style={{ color: 'red', fontSize: 12 }}>
                                    {t('Value_Required')}
                                </p>
                            }
                        </label>
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                                Voltar
                            </Button>
                        </Dialog.Close>
                        <Button type='submit' disabled={loading} color="green">
                            {loading && <Spinner loading>
                                <BookmarkIcon />
                            </Spinner>}
                            Ativar
                        </Button>
                    </Flex>
                </form>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default ActiveBonus;