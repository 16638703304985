
// import { Container } from './styles';

import { Avatar, Badge, Box, Button, Card, Checkbox, Code, DataList, Flex, Grid, IconButton, SegmentedControl, Skeleton, Spinner, Strong, Table, Text, Tooltip } from "@radix-ui/themes";
import { ArrowLeftCircleIcon, BookmarkIcon, CopyIcon, MapIcon, X } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { EBonusTabs } from "src/@types/bonus";
import { TClientsSearchResponse } from "src/@types/clients";
import { TEmailsResponse } from "src/@types/emails";
import { TLimitsResponse } from "src/@types/limits";
import { TRestrictionsResponse } from "src/@types/restrictions";
import { TDefaultResponse } from "src/@types/transactions";
import ActiveBonus from "src/components/ActiveBonus";
import AddTransaction from "src/components/AddTransaction";
import BlockClient from "src/components/BlockClient";
import CancelBonus from "src/components/CancelBonus";
import ClientNotes from "src/components/ClientNotes";
import ClientStatus from "src/components/ClientStatus";
import ClientTransactions from "src/components/ClientTransactions";
import EditUserDetails from "src/components/EditUserDetails";
import EditUserName from "src/components/EditUserName";
import MainLayout from "src/components/MainLayout";
import RemoveExclusion from "src/components/RemoveExclusion";
import ResetPassModal from "src/components/ResetPassModal";
import TablePaginated from "src/components/TablePaginated";
import { CardDescription, CardTitle } from "src/components/ui/card";
import ViewGGR from "src/components/ViewGGR";
import ViewMessage from "src/components/ViewMessage";
import useUserRole from "src/hooks/useUserRole";
import Bonus from "src/infraestructure/services/api/Bonus";
import Clients from "src/infraestructure/services/api/Clients";
import ResetPassword from "src/infraestructure/services/api/Clients/ResetPassword";
import Emails from "src/infraestructure/services/api/Emails";
import Limits from "src/infraestructure/services/api/Limits";
import Logins from "src/infraestructure/services/api/Logins";
import Messages from "src/infraestructure/services/api/Messages";
import Restrictions from "src/infraestructure/services/api/Restrictions";
import userProfileStyles from "src/styles/components/userProfileStyles";
import { copyToClipBoard, formatCurrency } from "src/utils";

const ClientProfile = () => {

    const { CardUserInfoContainer, ContainerCityInfo, CardWrapper, BackButtonStyle } = userProfileStyles();

    const [clientInfo, setClientInfo] = useState<TClientsSearchResponse>();
    const [loadingClientInfo, setLoadingClientInfo] = useState<boolean>(true);
    const [tabSelect, setTabSelect] = useState<EBonusTabs>(EBonusTabs.bonus);
    const [bonusList, setBonusList] = useState<TDefaultResponse>();
    const [limitsList, setLimitsList] = useState<TLimitsResponse>();
    const [loadingLimits, setLoadingLimits] = useState<boolean>(false);
    const [loadingLimitsType, setLoadingLimitsType] = useState<string>("");
    const [restrictionsList, setRestrictionsList] = useState<TRestrictionsResponse>();
    const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(false);
    const [loadingResetPassword, setLoadingResetPassword] = useState<boolean>(false);
    const [loadingRemoveExclusion, setLoadingRemoveExclusion] = useState<boolean>(false);
    const [emailsList, setEmailsList] = useState<TEmailsResponse[]>();
    const [messagesList, setMessagesList] = useState<TDefaultResponse>();
    const [resetedPassword, setResetedPassword] = useState<string>();
    const [loginsList, setLoginsList] = useState<TDefaultResponse>();
    const [status, setStatus] = useState<string>("");

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const role = useUserRole();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams && searchParams.get('tab')) {
            setTabSelect(searchParams.get('tab')?.toLowerCase() as EBonusTabs);
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        if (id) {
            const client = await Clients.get({
                clientId: parseInt(id)
            });

            if (client && client.data) {
                setClientInfo(client.data);
            }
            setLoadingClientInfo(false);
        }
    }

    useEffect(() => {
        if (tabSelect === EBonusTabs.bonus) {
            fetchBonusInfo();
        }
        if (tabSelect === EBonusTabs.limits) {
            fetchLimitsInfo();
        }
        if (tabSelect === EBonusTabs.restrictions) {
            fetchRestrictions();
        }
        if (tabSelect === EBonusTabs.emails) {
            fetchEmails();
        }
        if (tabSelect === EBonusTabs.messages) {
            fetchMessages();
        }
        if (tabSelect === EBonusTabs.logins) {
            fetchUserLogins();
        }
    }, [tabSelect]);

    const fetchUserLogins = async () => {
        if (id) {
            const logins = await Logins.getById({
                clientId: id,
                startDate: `09-08-24 - 00:00:00`,
                endDate: `10-09-24 - 23:59:59`
            });

            if (logins && logins.data) {
                setLoginsList(logins);
            }
        }
    }

    const fetchBonusInfo = async () => {
        if (id) {
            const bonus = await Bonus.getById({
                clientId: id,
                pageSize: 10
            });

            if (bonus && bonus.data) {
                setBonusList(bonus);
            }
        }
    }

    const handleChangeBonusPage = async (page: number) => {
        if (id) {
            const bonus = await Bonus.getById({
                clientId: id,
                page: page,
                pageSize: 10
            });

            if (bonus && bonus.data) {
                setBonusList(bonus);
            }
        }
    }

    const handleChangeLoginsPage = async (page: number) => {
        if (id) {
            const logins = await Logins.getById({
                clientId: id,
                page: page,
                pageSize: 10,
                startDate: '09-08-24 - 00:00:00',
                endDate: '10-09-24 - 23:59:59'
            });

            if (logins) {
                setLoginsList(logins);
            }
        }
    }

    const handleChangeMessagePage = async (page: number) => {
        if (id) {
            const messages = await Messages.getById({
                clientId: id,
                page: page,
                perPage: 10
            });

            if (messages) {
                setMessagesList(messages);
            }
        }
    }

    const fetchLimitsInfo = async () => {
        if (id) {
            setLoadingLimits(true);
            try{
                const limits = await Limits.getById({
                    clientId: id
                });
    
                if (limits) {
                    setLimitsList(limits.data);
                }
                setLoadingLimits(false);
            }catch(err){
                setLoadingLimits(false);
            }
        }
    }

    const fetchRestrictions = async () => {
        if (id) {
            const restrictions = await Restrictions.getById({
                clientId: id
            });
            if (restrictions) {
                setRestrictionsList(restrictions.data);
            }
        }
    }

    const fetchEmails = async () => {
        if (id) {
            const requestEmails = await Emails.getById({
                clientId: id
            });

            if (requestEmails && requestEmails.data) {
                setEmailsList(requestEmails.data);
            }
        }
    }

    const fetchMessages = async () => {
        if (id) {
            const requestMessages = await Messages.getById({
                clientId: id,
            });

            if (requestMessages && requestMessages.data) {
                setMessagesList(requestMessages);
            }
        }
    }

    const removeRestrictions = async () => {
        if (id) {
            setLoadingRestrictions(true);
            try{
                const restrictions = await Restrictions.save({
                    clientId: id
                });
                toast.success(t(restrictions.message))
                setLoadingRestrictions(false);
            }catch(err){
            }
        }
    }

    const removeLimit = async (type: string) => {
        if (id) {
            setLoadingLimitsType(type);
            try{

                const limits = await Limits.update({
                    clientId: id,
                    type: type
                });

                toast.success(t(limits.message))
                fetchLimitsInfo()
                setLoadingLimitsType("");
            }catch(err){
                setLoadingLimitsType("");
            }
        }
    }

    const handleResetPassword = async () => {
        if (id) {
            setLoadingResetPassword(true);
            const resetPass = await ResetPassword.save({
                clientId: parseInt(id)
            });

            if (resetPass && resetPass.status === 200) {
                copyToClipBoard(resetPass.data.new_password);
                toast.success(t('Password_Changed'));
                setResetedPassword(resetPass.data.new_password);
            }
            setLoadingResetPassword(false);
        }
    }

    const handleRemoveExclusion = async () => {
        if (id) {
            setLoadingRemoveExclusion(true);
            const removeAutoExclusion = await Clients.edit(id, {
                removeAutoExclusion: true
            });

            if(removeAutoExclusion && removeAutoExclusion.data) {
                toast.success(`Dados salvos com sucesso.`);
                setLoadingRemoveExclusion(false);
                let currentClientInfo = {...clientInfo};
                currentClientInfo['excluded'] = undefined;
                setClientInfo(currentClientInfo as any);
            }
        }
    }

    const handleFinishEditName = async (data: any, firstName: string, lastName: string) => {
        let currentClientInfo = {...clientInfo};
        currentClientInfo['first_name'] = data.FirstName;
        currentClientInfo['last_name'] = data.LastName;

        setClientInfo(currentClientInfo as any);
    }

    const handleFinishEdit = async (data: any, type: string) => {
        let currentClientInfo = {...clientInfo};
        console.log(currentClientInfo.is_verified);
        
        if(type === "cpf") {
            currentClientInfo['personal_id'] = data.personal_id;
        }
        if(type === "phone") {
            currentClientInfo['phone'] = data.phone;
        }
        if(type === "gender") {
            currentClientInfo['gender'] = data.gender;
        }
        if(type === "email") {
            currentClientInfo['email'] = data.email;
        }
        if(type === "date") {
            currentClientInfo['birth_date'] = data.birth_date;
        }
        if(type === "isVerified") {
            currentClientInfo['is_verified'] = data.is_verified;
        }

        setClientInfo(currentClientInfo as any);
    }

    const handleResultLock = async (result: string) => {
        let currentClientInfo = {...clientInfo};

        currentClientInfo['is_locked'] = result === "0" ? false : true;

        setClientInfo(currentClientInfo as any);
    }

    return (
        <MainLayout>
            <div className={BackButtonStyle} onClick={() => {
                navigate('/clients/list');
            }}>
                <ArrowLeftCircleIcon />
                <Text>{t('Voltar')}</Text>
            </div>
            <Grid columns=".3fr 1fr" gap="5" rows="repeat(1, auto)" width="auto">
                {loadingClientInfo &&
                    <Skeleton height={'auto'} width={'auto'} style={{ borderRadius: 10 }} />
                }
                {clientInfo && !loadingClientInfo && <Card className={CardUserInfoContainer}>
                    {clientInfo && clientInfo.name && <Avatar radius="full" fallback={clientInfo?.name[0].toUpperCase() as string} size={"8"} />}
                    <Flex align={"center"} gap={"2"}>
                    <Text size={"4"}>
                        <Strong>{clientInfo?.first_name} {clientInfo?.middle_name} {clientInfo?.last_name}</Strong>
                    </Text>
                    {['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2'].includes(role) && <EditUserName userId={String(clientInfo.id)} firstName={clientInfo.first_name} lastName={`${clientInfo.middle_name} ${clientInfo.last_name}`} onFinish={(e: any, firstName: string, lastName: string) => handleFinishEditName(e, firstName, lastName)} />}
                    {['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2'].includes(role) && <BlockClient clientId={String(clientInfo.id)} isLocked={clientInfo?.is_locked} onFinish={(result: string) => handleResultLock(result)} />}
                    </Flex>
                    <div className={ContainerCityInfo}>
                        <MapIcon></MapIcon>
                        <Text as="p">{clientInfo?.city}</Text>
                    </div>
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        <ClientStatus userId={String(clientInfo.id)} onFinish={(e: string) => { setStatus(e) }} />
                        {status ? <Badge color={status === "online" ? "green" : "red"}>{status}</Badge> : <div>-</div>}
                    </div>
                    <DataList.Root mt={"20px"}>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">CPF</DataList.Label>
                            <DataList.Value>
                                <Flex align="center" gap="2">
                                    <Code variant="ghost">{clientInfo?.personal_id}</Code>
                                    {['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2'].includes(role) && <EditUserDetails userId={String(clientInfo.id)} defaultValue={clientInfo.personal_id} type={"cpf"} onFinish={(e: any, type: string) => handleFinishEdit(e, type)} />}
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">ID</DataList.Label>
                            <DataList.Value>
                                <Flex align="center" gap="2">
                                    <Code variant="ghost">{clientInfo?.id}</Code>
                                    <IconButton
                                        size="1"
                                        aria-label="Copy value"
                                        color="gray"
                                        onClick={() => {
                                            if (clientInfo.id) {
                                                copyToClipBoard(String(clientInfo.id));
                                                toast.success(`Copiado com sucesso!`)
                                            }
                                        }}
                                        variant="ghost"
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">Saldo</DataList.Label>
                            <DataList.Value><Badge color="green">{formatCurrency(clientInfo.balance)}</Badge></DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">Gênero</DataList.Label>
                            <DataList.Value>
                                <Flex align={"center"} gap="2">
                                {t(clientInfo?.gender)}
                                <EditUserDetails userId={String(clientInfo.id)} defaultValue={clientInfo.gender} type={"gender"} onFinish={(e: any, type: string) => handleFinishEdit(e, type)} />
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>

                        <DataList.Item>
                            <DataList.Label minWidth="88px">Telefone</DataList.Label>
                            <DataList.Value>
                                <Flex align={"center"} gap="2">
                                    {clientInfo?.phone ? t(clientInfo?.phone) : "-"}
                                    <EditUserDetails userId={String(clientInfo.id)} defaultValue={clientInfo.phone} type={"phone"} onFinish={(e: any, type: string) => handleFinishEdit(e, type)} />
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>

                            <DataList.Label minWidth="88px">Login</DataList.Label>
                            <DataList.Value>
                                <Flex align={"center"} gap="2">
                                    {clientInfo?.login}
                                </Flex>
                            </DataList.Value>

                            <DataList.Label minWidth="88px">Email</DataList.Label>
                            <DataList.Value>
                                <Flex align={"center"} gap="2">
                                    {clientInfo?.email}
                                    <EditUserDetails userId={String(clientInfo.id)} defaultValue={clientInfo.email} type={"email"} onFinish={(e: any, type: string) => handleFinishEdit(e, type)} />
                                </Flex>
                            </DataList.Value>
                            <DataList.Label minWidth="88px">Nascimento</DataList.Label>
                                <DataList.Value>
                                    <Flex align={"center"} gap="2">
                                        {clientInfo?.birth_date ? clientInfo?.birth_date : ''}
                                        <EditUserDetails userId={String(clientInfo.id)} defaultValue={clientInfo.birth_date} type={"date"} onFinish={(e: any, type: string) => handleFinishEdit(e, type)} />
                                    </Flex>
                            </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                            <DataList.Label minWidth="88px">Categoria Jogador</DataList.Label>
                            <DataList.Value>
                                <Badge>
                                    {clientInfo?.sportsbook_profile}
                                </Badge>
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                            <DataList.Label minWidth="88px">Data Registro</DataList.Label>
                            <DataList.Value>
                                {clientInfo?.created && <Badge>
                                    {clientInfo?.created}
                                </Badge>}
                                {!clientInfo?.created && "-"}
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                            <DataList.Label minWidth="88px">Última Exclusão</DataList.Label>
                            <DataList.Value>
                                {clientInfo?.excluded_last && <Badge>
                                    {clientInfo?.excluded_last}
                                </Badge>}
                                {!clientInfo?.excluded_last && "-"}
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item style={{alignItems: 'center'}}>
                            <DataList.Label minWidth="88px">Verificado</DataList.Label>
                            <DataList.Value>
                            <Flex gap={"2"} align={"center"}>
                                <Badge style={{marginRight: 30}} color={clientInfo.is_verified ? "green" : "red"}>
                                    {clientInfo?.is_verified ? "Sim" : "Não"}
                                </Badge>
                                <EditUserDetails userId={String(clientInfo.id)} defaultValue={clientInfo.is_verified ? 'true' : 'false'} type={"isVerified"} onFinish={(e: any, type: string) => handleFinishEdit(e, type)} />
                            </Flex>
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                            <DataList.Label minWidth="88px">Excluído</DataList.Label>
                            <DataList.Value>
                                <Flex gap={"2"} align={"center"}>
                                    <Badge color={clientInfo?.excluded ? "green" : "red"}>
                                        {clientInfo?.excluded ? "Sim" : "Não"}
                                    </Badge>
                                    {id && clientInfo?.excluded && <RemoveExclusion onFinish={handleRemoveExclusion} loading={loadingRemoveExclusion} />}
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                            <DataList.Label minWidth="88px">Bloqueado</DataList.Label>
                            <DataList.Value>
                                <Flex gap={"2"} align={"center"}>
                                    <Badge color={clientInfo?.is_locked ? "green" : "red"}>
                                        {clientInfo?.is_locked ? "Sim" : "Não"}
                                    </Badge>
                                </Flex>
                            </DataList.Value>
                        </DataList.Item>

                    </DataList.Root>
                    <Grid align={"center"} mt={"20px"} gap={"10px"} columns={"auto auto"}>
                        {id && <ResetPassModal onFinish={handleResetPassword} loading={loadingResetPassword} />}
                        {id && <ViewGGR clientId={id} clientDocument={clientInfo.personal_id} clientName={clientInfo.name} />}
                        {id && ['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2'].includes(role) && <AddTransaction userId={id} />}
                    </Grid>
                    {resetedPassword &&
                        <Flex align="center" gap="2" mt={"10px"} direction={"column"}>
                            <div>Nova senha:</div>
                            <Flex align="center" gap="2">
                                <Code variant="ghost">{resetedPassword}</Code>
                                <IconButton
                                    size="1"
                                    aria-label="Copy value"
                                    color="gray"
                                    onClick={() => {
                                        copyToClipBoard(resetedPassword);
                                        toast.success(`Copiado com sucesso!`)
                                    }}
                                    variant="ghost"
                                >
                                    <CopyIcon />
                                </IconButton>
                            </Flex>
                        </Flex>
                    }
                </Card>}
                <Box>
                    <Grid columns="4" gap="5" rows="repeat(1, auto)" width="auto">
                        {loadingClientInfo &&
                            <>
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                                <Skeleton height={'150px'} width={'auto'} style={{ borderRadius: 10 }} />
                            </>
                        }
                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Liquid_Revenue')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{formatCurrency(((clientInfo?.statistics.withdrawal_amount - clientInfo?.statistics.deposit_amount) + clientInfo?.balance) * -1)}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Deposit_Count')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{clientInfo?.statistics.deposit_count}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Deposit_Amount')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{formatCurrency(clientInfo?.statistics.deposit_amount)}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('First_Deposit')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{clientInfo?.statistics.first_deposit_date}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Last_Deposit')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{clientInfo?.statistics.last_deposit_date}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Last_Deposit_Amount')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{formatCurrency(clientInfo?.statistics.last_deposit_amount)}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Withdrawal_Amount')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{formatCurrency(clientInfo?.statistics.withdrawal_amount)}</Text></CardDescription>
                        </Card>}

                        {!loadingClientInfo && clientInfo && <Card className={CardWrapper}>
                            <CardTitle><Text mt={'10px'} size={"1"} as="p">{t('Withdrawal_Count')}</Text></CardTitle>
                            <CardDescription><Text size={"7"} as="p" mt={'-20px'}>{clientInfo?.statistics.withdrawal_count}</Text></CardDescription>
                        </Card>}
                    </Grid>
                    {loadingClientInfo && <Skeleton height={'400px'} width={'auto'} mt={"20px"} style={{ borderRadius: 10 }} />}
                    {!loadingClientInfo && clientInfo && <Card mt={"20px"} className={CardWrapper}>
                        <CardTitle>{t('Info')} (Os dados podem demorar até 15min para serem atualizados)</CardTitle>
                        <SegmentedControl.Root defaultValue={tabSelect} style={{ width: 500 }} onValueChange={(e: EBonusTabs) => setTabSelect(e)}>
                            <SegmentedControl.Item value={EBonusTabs.bonus}>Bônus</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.limits}>Limites</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.restrictions}>Restrições</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.emails}>Emails</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.messages}>Mensagens</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.transactions}>Transações</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.logins}>Logins</SegmentedControl.Item>
                            <SegmentedControl.Item value={EBonusTabs.notes}>Notas</SegmentedControl.Item>
                        </SegmentedControl.Root>

                        <Box mt={'20px'}>
                            {tabSelect === EBonusTabs.transactions &&
                                <>
                                    <ClientTransactions personalId={clientInfo.personal_id} />
                                </>
                            }
                            {tabSelect === EBonusTabs.messages && messagesList && messagesList.data.length > 0 &&
                                <TablePaginated
                                    data={messagesList.data}
                                    totalItems={messagesList.pagination.total_items}
                                    keys={[
                                        {
                                            name: 'title',
                                            type: 'string'
                                        },
                                        {
                                            name: "message",
                                            type: "string",
                                            children: (response: any) => {
                                                return (
                                                    <>
                                                        <ViewMessage text={response.message} description={response.title} />
                                                    </>
                                                )
                                            }
                                        },
                                        {
                                            name: 'state',
                                            type: 'tag'
                                        },
                                        {
                                            name: 'created',
                                            type: 'string'
                                        }
                                    ]}
                                    perPage={10}
                                    onPageChange={handleChangeMessagePage}
                                />
                                // <Table.Root variant="surface">
                                //     <Table.Header>
                                //         <Table.Row>
                                //             <Table.ColumnHeaderCell>{t('Title')}</Table.ColumnHeaderCell>
                                //             <Table.ColumnHeaderCell>{t('Message')}</Table.ColumnHeaderCell>
                                //             <Table.ColumnHeaderCell>{t('State')}</Table.ColumnHeaderCell>
                                //             <Table.ColumnHeaderCell>{t('Created')}</Table.ColumnHeaderCell>
                                //         </Table.Row>
                                //     </Table.Header>

                                //     <Table.Body>
                                //         {messagesList?.map((i, index) => {
                                //             return (
                                //                 <Table.Row key={index}>
                                //                     <Table.Cell>{i.title}</Table.Cell>
                                //                     <Table.Cell>
                                //                         <ViewMessage text={i.message} description={i.title} />
                                //                     </Table.Cell>
                                //                     <Table.Cell>
                                //                         <Badge>
                                //                             {t(i.state)}
                                //                         </Badge>
                                //                     </Table.Cell>
                                //                     <Table.Cell>{i.created}</Table.Cell>
                                //                 </Table.Row>
                                //             )
                                //         })}
                                //     </Table.Body>
                                // </Table.Root>
                            }
                            {tabSelect === EBonusTabs.bonus && bonusList && bonusList?.data.length > 0 &&
                                <>
                                    <TablePaginated
                                        data={bonusList.data}
                                        totalItems={bonusList.pagination.total_items}
                                        keys={[
                                            {
                                                name: 'name',
                                                type: 'string'
                                            },
                                            {
                                                name: 'created',
                                                type: 'string'
                                            },
                                            {
                                                name: 'result_date',
                                                type: 'string'
                                            },
                                            {
                                                name: 'result',
                                                type: 'tag'
                                            },
                                            {
                                                name: 'acceptance_date',
                                                type: 'string'
                                            },
                                            {
                                                name: 'expiration_date',
                                                type: 'string'
                                            },
                                            {
                                                name: 'amount',
                                                type: 'money'
                                            },
                                            {
                                                name: 'paid_amount',
                                                type: 'money'
                                            },
                                            {
                                                name: 'state',
                                                type: 'tag'
                                            },
                                            {
                                                name: "actions",
                                                type: "string",
                                                children: (response: any) => {
                                                    return (
                                                        <Flex direction={"row"} gap={"2"} align={"center"}>
                                                            {id && ['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2'].includes(role) && response.acceptance_type === 1 && <ActiveBonus bonusId={response.bonus_id} userId={id} />}
                                                            {id && ['ADMINISTRATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2'].includes(role) && response.result_type === 0 && <CancelBonus bonusId={response.bonus_id} userId={id} />}
                                                        </Flex>
                                                    )
                                                }
                                            }
                                        ]}
                                        perPage={10}
                                        onPageChange={handleChangeBonusPage}
                                    />
                                </>
                            }
                            {tabSelect === EBonusTabs.emails &&
                                <Table.Root variant="surface">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColumnHeaderCell>{t('Title')}</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell>{t('Subject')}</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell>{t('State')}</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell>{t('created')}</Table.ColumnHeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {emailsList?.map((i, index) => {
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>{i.title}</Table.Cell>
                                                    <Table.Cell>{i.subject}</Table.Cell>
                                                    <Table.Cell>
                                                        <Badge>
                                                            {t(i.state)}
                                                        </Badge>
                                                    </Table.Cell>
                                                    <Table.Cell>{i.created}</Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table.Root>
                            }
                            {tabSelect === EBonusTabs.limits && loadingLimits &&
                                <Grid columns={"4"} rows={"3"} width={"auto"} gap={"5"}>
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'40px'} width={'auto'} style={{ borderRadius: 10 }} />
                                </Grid>
                            }
                            {tabSelect === EBonusTabs.limits && !loadingLimits && limitsList && Object.keys(limitsList).length &&
                                <Flex direction={"column"}>
                                    <Grid columns={"1fr 1fr 1fr 1fr .2fr"} rows={"3"} width={"auto"} gap={"5"}>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo da Aposta Simples</Strong></Text>
                                            <Text>R${limitsList?.bet_limit.casino.single}</Text>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo da Aposta Diária</Strong></Text>
                                            <Text>R${limitsList?.bet_limit.casino.single}</Text>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo da Aposta Semanal</Strong></Text>
                                            <Text>R${limitsList?.bet_limit.casino.weekly}</Text>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Aposta Máxima Mensal</Strong></Text>
                                            <Text>R${limitsList?.bet_limit.casino.monthly}</Text>
                                        </Flex>

                                        <Flex>
                                            <Tooltip content="Remover Limite de Apostas">
                                                <IconButton
                                                    color="red"
                                                    radius="full"
                                                    onClick={() => {
                                                        if (loadingLimitsType === "") {
                                                            removeLimit("bet");
                                                        }
                                                    }}
                                                >
                                                    {loadingLimitsType !== "bet" && <X size={22} />}
                                                    {loadingLimitsType === "bet" && <Spinner size={"2"} />}
                                                </IconButton>
                                            </Tooltip>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo de Depósito Simples</Strong></Text>
                                            <Text>R${limitsList?.deposit_limit.single}</Text>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo de Depósito do Dia</Strong></Text>
                                            <Text>R${limitsList?.deposit_limit.daily}</Text>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo de Depósito Semanal</Strong></Text>
                                            <Text>R${limitsList?.deposit_limit.weekly}</Text>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor Máximo de Depósito Mensal</Strong></Text>
                                            <Text>R${limitsList?.deposit_limit.monthly}</Text>
                                        </Flex>

                                        <Flex>
                                            <Tooltip content="Remover Limite de Depósitos">
                                                <IconButton
                                                    color="red"
                                                    radius="full"
                                                    onClick={() => {
                                                        if (loadingLimitsType === "") {
                                                            removeLimit("deposit");
                                                        }
                                                    }}
                                                >
                                                    {loadingLimitsType !== "deposit" && <X size={22} />}
                                                    {loadingLimitsType === "deposit" && <Spinner size={"2"} />}
                                                </IconButton>
                                            </Tooltip>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Esportes Diário</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.sports.daily}</Text>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Esportes Semanal</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.sports.weekly}</Text>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Esportes Mensal</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.sports.monthly}</Text>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Esportes Anual</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.sports.yearly}</Text>
                                        </Flex>

                                        <Flex>
                                            <Tooltip content="Remover Limite de Esportes">
                                                <IconButton
                                                    color="red"
                                                    radius="full"
                                                    onClick={() => {
                                                        if (loadingLimitsType === "") {
                                                            removeLimit("sportbook");
                                                        }
                                                    }}
                                                >
                                                    {loadingLimitsType !== "sportbook" && <X size={22} />}
                                                    {loadingLimitsType === "sportbook" && <Spinner size={"2"} />}
                                                </IconButton>
                                            </Tooltip>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Casino Diário</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.casino.daily}</Text>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Casino Semanal</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.casino.weekly}</Text>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Casino Mensal</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.casino.monthly}</Text>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            <Text><Strong>Valor de Perdas em Casino Anual</Strong></Text>
                                            <Text>R${limitsList?.loss_limits.casino.yearly}</Text>
                                        </Flex>

                                        <Flex>
                                            <Tooltip content="Remover Limite de Casino">
                                                <IconButton
                                                    color="red"
                                                    radius="full"
                                                    onClick={() => {
                                                        if (loadingLimitsType === "") {
                                                            removeLimit("casino");
                                                        }
                                                    }}
                                                >
                                                    {loadingLimitsType !== "casino" && <X size={22} />}
                                                    {loadingLimitsType === "casino" && <Spinner size={"2"} />}
                                                </IconButton>
                                            </Tooltip>
                                        </Flex>

                                        <Flex direction={"column"}>
                                            <Text><Strong>Limite em Saldo</Strong></Text>
                                            <Text>R${limitsList?.balance_limit}</Text>
                                        </Flex>
                                        <Flex></Flex>
                                        <Flex></Flex>
                                        <Flex></Flex>
                                        <Flex>
                                            <Tooltip content="Remover Limite de Saldo">
                                                <IconButton
                                                    color="red"
                                                    radius="full"
                                                    onClick={() => {
                                                        if (loadingLimitsType === "") {
                                                            removeLimit("balance");
                                                        }
                                                    }}
                                                >
                                                    {loadingLimitsType !== "balance" && <X size={22} />}
                                                    {loadingLimitsType === "balance" && <Spinner size={"2"} />}
                                                </IconButton>
                                            </Tooltip>
                                        </Flex>

                                    </Grid>
                                    {/* <Flex align={"center"} width={"100%"} justify={"center"} mt={"20px"}>
                                        {!loadingRestrictions && <Button style={{ minWidth: 300 }} variant="classic" onClick={removeLimits}>{t('Remove_Limits')}</Button>}
                                        {loadingRestrictions && <Button style={{ minWidth: 300 }} disabled variant="classic">
                                            <Spinner loading>
                                                <BookmarkIcon />
                                            </Spinner>
                                            {t('Remove_Limits')}
                                        </Button>}
                                    </Flex> */}
                                </Flex>
                            }
                            {tabSelect === EBonusTabs.restrictions && loadingRestrictions &&
                                <Grid columns={"4"} rows={"3"} width={"auto"} gap={"5"}>
                                    <Skeleton height={'24px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'24px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'24px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'24px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'24px'} width={'auto'} style={{ borderRadius: 10 }} />
                                    <Skeleton height={'24px'} width={'auto'} style={{ borderRadius: 10 }} />
                                </Grid>
                            }
                            {tabSelect === EBonusTabs.restrictions && !loadingRestrictions && restrictionsList && Object.keys(restrictionsList).length &&
                                <Flex direction={"column"}>
                                    <Grid columns={"4"} rows={"3"} width={"auto"} gap={"5"}>
                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode apostar</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_bet} />
                                        </Flex>

                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode Depositar</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_deposit} />
                                        </Flex>

                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode Entrar</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_login} />
                                        </Flex>

                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode Retirar</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_withdraw} />
                                        </Flex>

                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode Aumentar o Limite</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_increase_limit} />
                                        </Flex>

                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode Reivindicar Bônus</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_claim_bonus} />
                                        </Flex>

                                        <Flex align={"center"} gap={"10px"}>
                                            <Text><Strong>Pode Acessar ao Cassino</Strong></Text>
                                            <Checkbox checked={restrictionsList.can_casino_login} />
                                        </Flex>
                                    </Grid>
                                    <Flex align={"center"} width={"100%"} justify={"center"}>
                                        {!loadingRestrictions && <Button style={{ minWidth: 300 }} variant="classic" onClick={removeRestrictions}>{t('Remove_Restrictions')}</Button>}
                                        {loadingRestrictions && <Button style={{ minWidth: 300 }} disabled variant="classic">
                                            <Spinner loading>
                                                <BookmarkIcon />
                                            </Spinner>
                                            {t('Remove_Restrictions')}
                                        </Button>}
                                    </Flex>
                                </Flex>
                            }
                            {tabSelect === EBonusTabs.notes &&
                                <>
                                    {id && <ClientNotes userId={id} />}
                                </>
                            }
                            {tabSelect === EBonusTabs.logins && loginsList && loginsList?.data.length > 0 &&
                                <TablePaginated
                                    data={loginsList.data}
                                    totalItems={loginsList.pagination.total_items}
                                    keys={[
                                        {
                                            name: 'device',
                                            type: 'tag'
                                        },
                                        {
                                            name: 'end_time',
                                            type: 'string'
                                        },
                                        {
                                            name: 'ip_address',
                                            type: 'string'
                                        },
                                        {
                                            name: 'start_time',
                                            type: 'string'
                                        },
                                        {
                                            name: 'logout_reason',
                                            type: 'string'
                                        }
                                    ]}
                                    perPage={10}
                                    onPageChange={handleChangeLoginsPage}
                                />
                            }
                        </Box>
                    </Card>}
                </Box>
            </Grid>
        </MainLayout>
    );
}

export default ClientProfile;