import { Badge, Button, Card, Table } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IStatusSync, iWaiting, PaymentSystemConfig } from 'src/@types/admin';
import MainLayout from 'src/components/MainLayout';
import ModalPaymentConfig from 'src/components/ModalPaymentConfig';
import Config from 'src/infraestructure/services/api/Admin/Config';

const AdminConfig = () => {

    const [payments, setPayments] = useState<PaymentSystemConfig[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [statusSync, setStatusSync] = useState<IStatusSync>()
    const [waiting, setWaiting] = useState<iWaiting>({ hours: 0, minutes: 0, seconds: 0 })
    const { hours, minutes, seconds } = waiting;
    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const getConfig = await Config.get();
        
        if (getConfig && getConfig.data) {
            setPayments(getConfig.data.payments_list);
        }

        try {
            const getSync = await Config.syncStatus()

            if(getSync){
                setStatusSync(getSync);
                if(getSync.waiting){
                    setWaiting(getSync.waiting)
                }
            }
        }catch(err){

        }

    }

    const handleFinish = async () => {
        const getConfig = await Config.get();

        console.log(getConfig);
        if (getConfig && getConfig.data) {
            console.log(getConfig.data);

            setPayments(getConfig.data.payments_list);
        }
    }

    const handleSynchronize = async () => {
        setIsLoading(true)
        try {
            await Config.sync();
            setIsLoading(false)
        }catch(err){
            setIsLoading(false)
        }
    }

    return (
        <MainLayout>
            <Card style={{marginBottom: 30, paddingRight: 30, display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                <h3>{t('Synchronize_Casino')}</h3>
                <p style={{fontSize: 12, color:'#898B8E' }}>{statusSync?.can_sync ? <Badge color='green'>{t(statusSync?.message)}</Badge> : <Badge color='red'>{t('A sync has already started', { hours, minutes, seconds })}</Badge>}</p>
                <Button 
                    style={{cursor: 'pointer'}} 
                    loading={isLoading} 
                    onClick={handleSynchronize}
                    >
                        Sincronizar
                    </Button>
            </Card>
            <Card style={{ padding: 10 }}>
                <h3>{t('Payment_Methods')}</h3>
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColumnHeaderCell>{t('Name')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Deposit')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Withdraw')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Order_Deposit')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Order_Withdraw')}</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>{t('Actions')}</Table.ColumnHeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {payments?.map((i, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{i.payment_system}</Table.Cell>
                                    <Table.RowHeaderCell>{i.active_deposit ? <Badge color='green'>Ativo</Badge> : <Badge color='red'>Desativado</Badge>}</Table.RowHeaderCell>
                                    <Table.Cell>{i.active_withdrawal ? <Badge color='green'>Ativo</Badge> : <Badge color='red'>Desativado</Badge>}</Table.Cell>
                                    <Table.Cell>{i.order_deposit}</Table.Cell>
                                    <Table.Cell>{i.order_withdrawal}</Table.Cell>
                                    <Table.Cell>
                                        <ModalPaymentConfig data={i} paymentsCount={payments.length} paymentsList={payments} onFinish={() => handleFinish()} />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table.Root>
            </Card>
        </MainLayout>
    );
}

export default AdminConfig;