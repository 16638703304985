import { TKYCSearchClients } from "src/@types/kyc";
import Gateway from "../Gateway";

const route = "/v1/kyc/lookup";
const routeEdit = "/v1/kyc/change-status/:id";

const get = async (data: TKYCSearchClients) => Gateway({
    url: route,
    filters: [
        {
            name: "personalId",
            value: data.personalId as any,
        }
    ]
});

const edit = async (clientId: string) => Gateway({url: routeEdit,  method: "PUT", id: clientId });

export default {
    get,
    edit
};